<template>
  <ValidationProvider tag="div" class="single-answer column is-half" :rules="validationRules" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field v-for="answer in question.answers" :key="answer.id">
          <b-radio
              :value="value[`${question.qid}`]"
              @input="onChange"
              :native-value="answer.code"
              :disabled="loading"
          >
            <span v-html="answer.answer[lang]"></span>
          </b-radio>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'SingleAnswer',
  mixins: [QuestionMixin],
  methods: {
    onChange (value) {
      this.$emit('input', { [this.question.qid]: `${value}` })
    }
  }
}
</script>
