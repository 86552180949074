import { questionKey } from '@/utils/question'

export const QuestionMixin = {
  props: {
    loading: {
      type: Boolean
    },
    lang: {
      type: String,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Object, Date],
    },
    showQuestionCode: {
      type: [String, Number, Boolean],
      default: false,
    }
  },
  data () {
    return {
      isExclusive: false,
    }
  },
  computed: {
    qOpts () {
      return this.question?.options || {}
    },
    validationRules () {
      const rules = {}
      if (this.qOpts.mandatory) rules.mandatory = { type: this.question.type }
      if (this.qOpts.min && this.qOpts.max && !this.isExclusive) rules.between = {
        type: this.question.type,
        min: this.qOpts.min,
        max: this.qOpts.max
      }
      else if (this.qOpts.min && !this.isExclusive) rules.min = { type: this.question.type, min: this.qOpts.min }
      else if (this.qOpts.max) rules.max = { type: this.question.type, max: this.qOpts.max }
      return rules
    }
  },
  methods: {
    questionKey: questionKey,
  },
}
